
  import {defineComponent, ref, computed, watch} from "vue"
  import $RefParser from '@apidevtools/json-schema-ref-parser'
  import {useModelWrapper} from "@/utils/modelWrapper"
  import { scopeToDataPointer, scopeFromRefToDataPointer } from "../JsonSchema/path-handler"

  interface Props {
    schema: any,
    uiSchema: any,
    parentScope: string
  }

  export default defineComponent({
    model: {
      prop: 'elementValue',
      event: 'update:elementValue'
    },
    props: {
      schema: {
        type: Object,
        required: true
      },
      uiSchema: {
        type: Object,
        required: true
      },
      parentValue: null,
      required: {
        type: Boolean,
        default: false
      },
      parentScope: {
        type: String,
        required: true
      },
      options: {
        type: Object
      }
    },
    setup(props: Props, { emit }) {
      const refPath = props.uiSchema?.$ref?.replace(props.schema.$id, '')
      const currentScope = scopeFromRefToDataPointer(props.parentScope, refPath)

      const getSchemaElement = async () => {
        if (!refPath) return undefined
        const parser = new $RefParser()
        const schema = await parser.dereference(props.schema)
        const $refs = await parser.resolve(props.schema)
        const targetedSchema = $refs.get(refPath)
        return targetedSchema
      }

      const schemaElement = ref()
      getSchemaElement().then(result => {
        schemaElement.value = result
      })

      const type = computed(() => {
        return async () => {
          // First check on widget
          switch(props.uiSchema['ui:type']) {
            case 'group':
              return import('./FormElementWrapper.vue')
            case 'checkbox':
              return import('./CheckboxElement.vue')
            case 'radio':
              return import('./RadioButtonElement.vue')
            case 'collection':
              return import('./ArrayElement.vue')
            case 'input':
              return import('./PrimitiveElement.vue')
            default:
              // Type is not defined, try to assume from model schema data type
              const targetedSchema = schemaElement.value
              if (!targetedSchema) {
                return import('./PrimitiveElement.vue')
              }
              switch(targetedSchema.type) {
                case 'object':
                  return import('./FormElementWrapper.vue')
                case 'boolean':
                  return import('./CheckboxElement.vue')
                case 'array':
                  return import('./ArrayElement.vue')
                default:
                  return import('./PrimitiveElement.vue')
              }
          }
        }
      })

      const title = computed(() => {
        let title = props.uiSchema['ui:label'] ?? schemaElement.value?.title
        if (schemaElement.value?.required && title) {
          title += " *"
        }
        return title
      })

      const childUiSchema = props.uiSchema.properties ?? props.uiSchema.items

      return {
        currentScope,
        refPath,
        type,
        title,
        childUiSchema
      }
    }
  })
