import { JSONSchemaType } from './types'
import {
  concatFormPointer,
  getSplitPointer,
} from './logic'

const getDataFromPointer = (
  pointer: string,
  data: JSONSchemaType
): undefined | string => {
  const splitPointer = getSplitPointer(pointer)

  let insideProperties = false

  return splitPointer
    .reduce(
      (currentContext, node: string) => {
        if (node === 'properties' && !insideProperties) {
          insideProperties = true
          return { ...currentContext, insideProperties: true }
        }
        insideProperties = false

        return {
          currentData: currentContext.currentData
            ? currentContext.currentData[node]
            : undefined,
          insideProperties: true,
        }
      },
      { currentData: data, insideProperties: false }
    )
    .currentData?.toString()
}

const schemaPointerToDataPointer = (
  pointer: string,
  schema: JSONSchemaType
): string => {
  const splitPointer = getSplitPointer(pointer)

  let dataFields: string = ""
  for (const field of splitPointer) {
      if (field === 'properties') {
          //if its json-schema property
          if (schema.hasOwnProperty('type') && schema.type === 'object') {
              //go next level inside schema
              schema = schema[field]
              continue
          }
      }

      //if field actually exist in schema
      if(schema.hasOwnProperty(field)) {
          //go next level inside schema
          schema = schema[field]
          //for the first time just add field, later add '.' + field
          dataFields += '/' + field
      }
  }
  return dataFields
}

const scopeToDataPointer = (
  parentScope: string,
  currentScope?: string
): string => {
  if (!currentScope) return parentScope
  const splitPointer = getSplitPointer(currentScope)
  if (splitPointer.length === 2 && splitPointer[0] === 'properties') {
    return parentScope !== '#' && parentScope !== '/' && !currentScope.startsWith('/') ? `${parentScope}/${splitPointer[1]}` : `/${splitPointer[1]}`
  }
  return splitPointer[0] ?? ""
}

const scopeFromRefToDataPointer = (
  parentScope: string,
  refPath?: string
): string => {
  if (!refPath) return parentScope
  const splitPointer = getSplitPointer(refPath)
  if (splitPointer.length >= 2 && splitPointer[splitPointer.length - 2] === 'properties') {
    return parentScope !== '#' && parentScope !== '/' ? `${parentScope}/${splitPointer[splitPointer.length - 1]}` : `/${splitPointer[splitPointer.length - 1]}`
  }
  if (splitPointer.length >= 2 && splitPointer[splitPointer.length - 1] === 'items' && splitPointer[splitPointer.length - 2] !== 'properties') {
    return parentScope
  }
  return splitPointer[0] ?? ""
}

export {
  concatFormPointer,
  getDataFromPointer,
  schemaPointerToDataPointer,
  scopeToDataPointer,
  scopeFromRefToDataPointer
}
