
  import { defineComponent, ref, watch, getCurrentInstance } from "vue"
  import { provideForm, getForm } from "@/components/shared/form/form-provider"
  import FormElement from "@/components/shared/form/elements/FormElement.vue"

  const formSymbol = Symbol()

  interface Props {
    schema: any,
    uiSchema: any,
    form?: any
  }

  export default defineComponent({
    components: {
      FormElement
    },
    props: {
      schema: {
        type: Object,
        required: true
      },
      uiSchema: {
        type: Object,
        required: true
      },
      form: {
        type: Object,
        default: () => ({})
      }
    },
    setup(props: Props, { emit }) {
      const root = getCurrentInstance().proxy
      provideForm(props.form)

      const internalForm = getForm()

      watch(() => internalForm.value, (newValue) => emit('form-updated', newValue))

      const propertyRequired = (schema: any, key: string) => {
        return !!schema.required && schema.required.includes(key)
      }

      return {
        //items,
        internalForm,
        propertyRequired
      }
    }
  })
